import React, { useEffect, useState } from "react";
import { USER_URL } from "../../../constants";
import OrderList from "../../../components/UserComponents/orders/OrderList";
import axiosInstance from "../../../utils/api/axiosInstance";
import showToast from "../../../utils/showToast";
import Loader from "../../../components/UserComponents/Loader";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance(USER_URL + "/orders")
      .then(({ data }) => setOrders(data?.data))
      .catch((e) => showToast(e.message, "error"))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="">
        <h1 className="text-2xl font-semibold font-poppins mb-2 px-2 py-2 mt-3">
          Manage your orders
        </h1>
        {orders && orders.length ? (
          <div className="space-y-3">
            {orders.map((order) => (
              <div className="space-y-3" key={order._id}>
                {order?.products.map((product) => (
                  <OrderList
                    {...product}
                    key={product._id}
                    orderId={order._id}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <h3 className="py-5 font-medium text-lg px-3 text-gray-400">
            No orders found
          </h3>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;
