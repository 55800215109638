import React from "react";
const LoadingSkeleton = () => {
  return (
    <div className="flex flex-col lg:flex-row  gap-4 animate-pulse space-y-4 p-4">
      <div className="h-[450px] w-full lg:w-1/2 bg-gray-300 rounded-md"></div>

      <div className="w-full lg:w-1/2 space-y-2">
        <div className="h-6 w-3/4 bg-gray-300 rounded"></div>

        {/* Price */}
        <div className="h-4 w-1/4 bg-gray-300 rounded"></div>

        {/* Rating */}
        <div className="flex items-center space-x-2">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-4 w-4 bg-gray-300 rounded-full"></div>
          ))}
        </div>

        {/* Description */}
        <div className="space-y-2">
          <div className="h-4 w-full bg-gray-300 rounded"></div>
          <div className="h-4 w-5/6 bg-gray-300 rounded"></div>
          <div className="h-4 w-4/6 bg-gray-300 rounded"></div>
          <div className="h-4 w-3/6 bg-gray-300 rounded"></div>
        </div>

        {/* Buttons */}
        <div className="flex space-x-4 mt-4">
          <div className="h-10 w-32 bg-gray-300 rounded-md"></div>
          <div className="h-10 w-32 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
