import React from "react";
import { motion } from "framer-motion";
import useDisableBodyScroll from "../../../Hooks/useDisableBodyScroll";
import { hostname } from "../../../constants";
import { logout } from "../../../utils";
import { ShieldAlert } from "lucide-react";
import { useAuthExpirationModal } from "../../../context/AuthExpiration";
import { useLocation, useNavigate } from "react-router-dom";

const SessionExpiredModal = () => {
  const { isModalOpen, setModalOpen } = useAuthExpirationModal();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useDisableBodyScroll(isModalOpen);

  const handleLogout = () => {
    logout();
    setModalOpen(false);
    const isAdmin = pathname.includes("/admin");
    const isVendor = pathname.includes("/vendor");
    navigate(isAdmin ? "/admin/login" : isVendor ? "/vendor/login" : "/login");
  };
  if (!isModalOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-black opacity-50" />

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
        className="relative z-50 mx-2 max-w-sm p-6 bg-white-A700 dark:bg-dark-card rounded-lg shadow-lg text-center"
      >
        <h2 className="inline-flex items-center gap-1 text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
          <ShieldAlert /> Session Expired
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-6">
          Hey, Your session has expired. Please log in to continue using{" "}
          {hostname}
        </p>
        <button
          onClick={handleLogout}
          className="w-full py-2 px-4 bg-gray-800 hover:bg-gray-900 text-white-A700 font-semibold rounded-lg dark:bg-gray-900"
        >
          Log In
        </button>
      </motion.div>
    </div>
  );
};

export default SessionExpiredModal;
