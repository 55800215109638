import React, { useEffect } from "react";
import OrderDetail from "../../../components/AdminComponents/Orders/OrderDetails/";
import { usePageTitle } from "../../../context/PageTitle";

const OrderDetailPage = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Order Details"), []);
  return (
    <div className="flex h-full">
      <OrderDetail />
    </div>
  );
};

export default OrderDetailPage;
