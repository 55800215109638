import { useEffect } from "react";
import ProductList from "../../../components/AdminComponents/Products";
import { usePageTitle } from "../../../context/PageTitle";

const Products = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Products"), []);
  return (
    <div className="flex h-full">
      <ProductList />
    </div>
  );
};

export default Products;
