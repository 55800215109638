const AddressSkeleton = ({ isAddressList = false }) => {
  return (
    <div className="relative w-full lg:max-w-[200px]  flex items-start pt-2 gap-2 animate-pulse">
      {!isAddressList && (
        <div className="ml-3 flex items-center h-5">
          <div className="h-4 w-4 bg-gray-300 rounded-full"></div>
        </div>
      )}

      <div
        className={`w-full bg-gray-100 rounded-lg shadow-sm p-4 border border-gray-200 space-y-2 sm:order-2`}
      >
        <div className="h-4 w-24 bg-gray-300 rounded-md"></div>

        <div className="h-2 w-full bg-gray-300 rounded-md"></div>

        <div className="flex flex-col gap-2">
          <div className="h-2 w-20 bg-gray-300 rounded-md"></div>
          <div className="h-2 w-28 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default AddressSkeleton;
