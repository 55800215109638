import { LogOut } from "lucide-react";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

const DropDownOptions = ({ user, handleLogout, handleVisibility }, ref) => {
  return (
    <div
      // ref={ref}
      className="absolute top-10 right-0  mt-2 w-48 bg-white-A700 border border-gray-200 rounded-md shadow-lg z-50"
    >
      <ul
        className="py-1"
        onClick={(e) => e.target.tagName === "A" && handleVisibility()}
      >
        <li className="block px-4 py-2 text-gray-800 hover:bg-gray-100 break-words border-b">
          Hey, {user?.name || "User"}
        </li>
        <li>
          <Link
            to="/account"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
          >
            Account
          </Link>
        </li>
        <li>
          <Link
            to="/wishlist"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
          >
            Wishlist
          </Link>
        </li>

        <li>
          <button
            className="inline-flex w-full items-center gap-2 px-4 py-2 text-gray-800 hover:bg-gray-100"
            onClick={handleLogout}
          >
            Logout <LogOut className="size-4 text-red-500" />
          </button>
        </li>
      </ul>
    </div>
  );
};
export default forwardRef(DropDownOptions);
