import React from "react";
import { useNavigate } from "react-router-dom";

const Banners = ({
  name = " Starting from: $49.99",
  description = `Exclusive collection for everyone`,
  url,
  image = "/images/carousel1.png",
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="aspect-[16/8] lg:aspect-[16/6.5] rounded-md cursor-pointer"
      onClick={() => navigate(url)}
    >
      <img
        src={image}
        alt="Hero section"
        className="w-full h-full rounded-md object-cover"
      />
    </div>
  );
};

export default Banners;
