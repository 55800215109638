import React, { useRef, useState } from "react";
import { Text, Img } from "./..";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../ToggleSwitch";
import useOutsideAlerter from "../../../Hooks/OutsideClickAlerter";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlignJustify, X } from "lucide-react";
import { logout } from "../../../utils";
import CategoryMenu from "./CategoryMenu";
import showToast from "../../../utils/showToast";
import "instantsearch.css/themes/satellite.css";
import SearchBox from "./SearchBox";
import { toggleUserType } from "../../../redux/slices/UserSlice";
import { useSearchBox } from "react-instantsearch";
import DropdownOptions from "./DropDownOptions";
import useFetchApi from "../../../Hooks/useFetchApi";
import { USER_URL } from "../../../constants";

export default function Header({ className = "sticky top-0 z-50" }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { refine } = useSearchBox();
  const { data: categories } = useFetchApi({ url: USER_URL + "/categories" });
  useOutsideAlerter(dropdownRef, handleVisibility);

  function handleVisibility() {
    setIsDropdownVisible(false);
  }
  const toggleMode = () => {
    const B2BStatus = !user?.isB2B;
    dispatch(toggleUserType(B2BStatus));
    if (B2BStatus) {
      document.body.style.opacity = 0.5;
      document.body.style.overflow = "hidden";
      navigate("/shop");
      setTimeout(() => {
        document.body.style.opacity = 1;
        document.body.style.overflow = "auto";
      }, 2 * 1000);
    }
    refine("");
  };

  const toggleDropDown = () => setIsDropdownVisible(!isDropdownVisible);

  const handleLogout = () => {
    logout();
    navigate("/");
    showToast("Logged out successfully");
  };

  return (
    <>
      {/* Mobile Screens */}
      <header
        className={`${className} lg:hidden flex justify-center items-center py-2 border-gray-200 border-b border-solid bg-white-A700`}
      >
        <div className="container-xs flex items-center justify-between gap-3 md:flex-col md:p-2 ">
          <div className="flex gap-2 justify-around md:justify-items-end w-full ">
            <Img
              src="/images/1.png"
              alt="header logo"
              className="size-10 object-contain cursor-pointer"
              onClick={() => navigate("/", { replace: true })}
            />
            <div className="hidden relative sm:flex items-center ">
              {showMenu ? (
                <X
                  onClick={() => setShowMenu(!showMenu)}
                  onMouseDown={(e) => e.preventDefault()}
                  className="size-6"
                />
              ) : (
                <AlignJustify
                  onClick={() => setShowMenu(!showMenu)}
                  onMouseDown={(e) => e.preventDefault()}
                  className="size-6"
                />
              )}
            </div>
            <div className="flex items-center justify-end gap-3  md:w-full">
              <div className="flex items-center">
                <span className="mr-2">{user?.isB2B ? "B2B" : "B2C"}</span>
                <ToggleSwitch
                  isOn={user?.isB2B}
                  handleToggle={toggleMode}
                  className=""
                />
              </div>
              {user?.isAuthenticated ? (
                <>
                  <div className="relative">
                    <Img
                      src="/images/user.svg"
                      alt="user button"
                      className="h-[24px] w-[24px] self-end cursor-pointer"
                      onClick={toggleDropDown}
                    />
                    {isDropdownVisible && (
                      <DropdownOptions
                        handleLogout={handleLogout}
                        user={user}
                        handleVisibility={handleVisibility}
                      />
                    )}
                  </div>

                  <Link to="/cart" className="relative">
                    <Img
                      src="/images/cart.svg"
                      alt="cart icon"
                      className="m-auto h-[24px] w-[24px] cursor-pointer"
                    />
                    <Text
                      size="xs"
                      as="p"
                      className="absolute -top-2 -right-2  m-auto flex h-[20px] w-[20px] items-center justify-center rounded-[10px] bg-light_blue-600 text-center !text-white-A700"
                    >
                      {cart?.length}
                    </Text>
                  </Link>
                </>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="bg-gray-700 hover:bg-gray-800 text-white-A700 px-6 py-2 font-medium rounded-2xl "
                >
                  Login
                </button>
              )}
            </div>
          </div>

          <SearchBox />
        </div>
      </header>

      {/* Large screens */}
      <header
        className={`${className} hidden  lg:flex justify-center items-center py-1 border-gray-200 border-b border-solid bg-white-A700`}
      >
        <div className="container-xs flex items-center justify-between gap-3 md:flex-col md:p-1 px-3">
          <div className="flex gap-2 items-center">
            <Img
              src="/images/1.png"
              alt="header logo"
              className="h-[46px] w-[119px] object-contain cursor-pointer"
              onClick={() => navigate("/", { replace: true })}
            />
            <div className="relative flex items-center">
              {showMenu ? (
                <X
                  onClick={() => setShowMenu(!showMenu)}
                  onMouseDown={(e) => e.preventDefault()}
                  className="size-6"
                />
              ) : (
                <AlignJustify
                  onClick={() => setShowMenu(!showMenu)}
                  onMouseDown={(e) => e.preventDefault()}
                  className="size-6"
                />
              )}
              {showMenu && (
                <CategoryMenu
                  categories={categories}
                  handleVisibility={() => setShowMenu(false)}
                />
              )}
            </div>
          </div>

          <SearchBox />
          <div className="flex items-center">
            <span className="mr-2">{user?.isB2B ? "B2B" : "B2C"}</span>
            <ToggleSwitch isOn={user?.isB2B} handleToggle={toggleMode} />
          </div>
          <div className=" flex w-[6%] items-center justify-end gap-[22px]  md:w-full">
            {user?.isAuthenticated ? (
              <>
                <div className="relative">
                  <Img
                    src="/images/user.svg"
                    alt="user button"
                    className="h-[24px] w-[24px] self-end cursor-pointer"
                    onClick={toggleDropDown}
                  />
                  {isDropdownVisible && (
                    <DropdownOptions
                      handleLogout={handleLogout}
                      user={user}
                      ref={dropdownRef}
                      handleVisibility={handleVisibility}
                    />
                  )}
                </div>

                <Link to="/cart" className="relative">
                  <Img
                    src="/images/cart.svg"
                    alt="cart icon"
                    className="m-auto h-[24px] w-[24px] cursor-pointer"
                  />
                  <Text
                    size="xs"
                    as="p"
                    className="absolute -top-2 -right-2  m-auto flex h-[20px] w-[20px] items-center justify-center rounded-[10px] bg-light_blue-600 text-center !text-white-A700"
                  >
                    {cart?.length}
                  </Text>
                </Link>
              </>
            ) : (
              <button
                onClick={() => navigate("/login")}
                className="bg-gray-700 hover:bg-gray-800 text-white-A700 px-6 py-2 font-medium rounded-2xl "
              >
                Login
              </button>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
