import React from "react";
import { Helmet } from "react-helmet";

const SEOComponent = ({ title, description }) => {
  return (
    <Helmet>
      <title>
        {title || "Shop the Latest Fashion and Accessories - Nexton eCommerce"}
      </title>
      <meta
        name="description"
        content={
          description ||
          "Discover the latest in men's and women's fashion, kids' toys, and accessories at Nexton eCommerce. Find your style with our range of products, from affordable to luxury items. Shop now for the best deals!"
        }
      />
    </Helmet>
  );
};

export default SEOComponent;
