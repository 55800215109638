import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { IoMailOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import QuoteForm from "./QuoteFom";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
const B2BContactSection = ({ name, _id, thumbnail }) => {
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState("");
  const handleVisibilty = () => {
    setShowForm(!showForm);
    setMessage("");
  };
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const handleLogin = () => navigate("/login", { state: `/product/${_id}` });

  return (
    <>
      <p className="text-lg font-semibold text-gray-700 mb-4 text-center">
        Interested in bulk purchasing or need more information? Get in touch
        with us directly!
      </p>
      <div className="flex sm:flex-col  items-center justify-center gap-5">
        <Link
          to={`https://api.whatsapp.com/send?phone=917902427009&text=${encodeURIComponent(
            `Hey, I'm interested in this product: ${name}`
          )}`}
          target="_blank"
          className="inline-flex items-center bg-green-500 hover:bg-green-600 text-white-A700 px-5 py-2 gap-2 font-medium rounded-full shadow-lg transition-all duration-300"
          aria-label="Start chat on WhatsApp"
        >
          <FaWhatsapp className="text-white-A700" size={24} />
          Chat via WhatsApp
        </Link>
        <Link
          to={`tel:80756206232`}
          target="_blank"
          className="inline-flex items-center bg-blue-500 hover:bg-blue-600  text-white-A700 px-5 py-3 gap-2 font-medium rounded-full shadow-lg transition-all duration-300"
          aria-label="Call Us"
        >
          <FiPhone className="text-white-A700" size={24} /> Call Us
        </Link>
        <Link
          to={"mailto:contact@example.com"}
          target="_blank"
          className="inline-flex items-center bg-gray-600 hover:bg-gray-700 text-white-A700 px-5 py-3 gap-2 font-medium rounded-full shadow-lg transition-all duration-300"
          aria-label="Send a mail"
        >
          <IoMailOutline className="text-white-A700" size={24} />
          Mail us
        </Link>
      </div>
      {/* <div className="flex items-center justify-center mt-2">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="px-4 text-gray-500">OR</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      <div>
        <div className="max-w-md mx-auto mt-1  bg-white-A700 ">
          <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            Request a Quote
          </h2>

          {user?.isAuthenticated && user?.role === "user" ? (
            <div className="flex justify-center">
              {message ? (
                <p className="text-sm font-medium text-green-500">
                  {message}
                  <span
                    onClick={handleVisibilty}
                    className="text-sm ml-1 text-blue-400 cursor-pointer"
                  >
                    Request another one.
                  </span>
                </p>
              ) : (
                <button
                  onClick={handleVisibilty}
                  className="py-3 px-6 text-white-A700 font-medium bg-gray-800 rounded-full hover:bg-gray-900 focus:outline-none"
                >
                  click here
                </button>
              )}
            </div>
          ) : (
            <div className="text-center">
              <p className="text-gray-600 mb-4">
                Please log in to request a quote.
              </p>
              <button
                onClick={handleLogin}
                className="py-3 px-6 text-white-A700 font-medium bg-blue-600 rounded-full hover:bg-blue-700 focus:outline-none"
              >
                Login to Continue
              </button>
            </div>
          )}
        </div>
        <AnimatePresence>
          {showForm && (
            <QuoteForm
              handlevisibilty={handleVisibilty}
              setMessage={setMessage}
              productData={{ name, _id, thumbnail }}
            />
          )}
        </AnimatePresence>
      </div> */}
    </>
  );
};

export default B2BContactSection;
