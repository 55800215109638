const {
  REACT_APP_ALGOLIA_APPLICATION_ID,
  REACT_APP_ALGOLIA_SEARCH_API_KEY,
  REACT_APP_ALGOLIA_INDEX_NAME,
  REACT_APP_BASE_URL,
  REACT_APP_CLOUDNAME,
  REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  REACT_APP_OAUTH_CLIENT_KEY,
  REACT_APP_STRIPE_PUBLISHABLE_KEY,
  REACT_APP_PAYPAL_CLIENT_ID,
} = process.env;

const env = {
  BASE_URL: REACT_APP_BASE_URL,
  CLOUDNAME: REACT_APP_CLOUDNAME,
  CLOUDINARY_UPLOAD_PRESET: REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  OAUTH_CLIENT_KEY: REACT_APP_OAUTH_CLIENT_KEY,
  STRIPE_PUBLISHABLE_KEY: REACT_APP_STRIPE_PUBLISHABLE_KEY,
  PAYPAL_CLIENT_ID: REACT_APP_PAYPAL_CLIENT_ID,
  ALGOLIA_APPLICATION_ID: REACT_APP_ALGOLIA_APPLICATION_ID,
  ALGOLIA_SEARCH_API_KEY: REACT_APP_ALGOLIA_SEARCH_API_KEY,
  ALGOLIA_INDEX_NAME: REACT_APP_ALGOLIA_INDEX_NAME,
};
export default env;
