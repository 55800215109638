import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { darkColor } from "../../../constants";
import useDisableBodyScroll from "../../../Hooks/useDisableBodyScroll";
import { getCategories } from "../../../utils/api/products";
const FilterDrawer = ({
  isOpen,
  onClose,
  filterValues,
  handleFiltersSelection,
  resetFilters,
  handleFilterApply,
}) => {
  const [categories, setCategories] = useState([]);
  const sidebarBackgroundColor = useColorModeValue("white", darkColor);
  const btnRef = useRef(null);
  useDisableBodyScroll(isOpen);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const { data } = await getCategories();
        setCategories(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchCategories();
  }, []);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    handleFiltersSelection(value ? { [name]: value } : {});
  };
  const selectClasses = `w-full p-2 border border-gray-300 focus:border-gray-300
   dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700
   text-gray-900 dark:text-gray-300`;

  const labelClasses = `block
   text-gray-700 dark:text-gray-300 font-bold mb-1`;
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
        <DrawerCloseButton
          zIndex="3"
          onClose={onClose}
          _focus={{ boxShadow: "none" }}
          _hover={{ boxShadow: "none" }}
        />
        <DrawerBody maxW="285px" px="0rem" pb="0">
          <h2 className="text-lg font-semibold px-2 text-gray-800 dark:text-gray-100">
            Filters
          </h2>
          <hr className="mt-2" />

          <div className="mt-2 p-4 mb-10">
            {/* Sort by Asc/Desc */}
            <div className="mb-3">
              <label className={labelClasses}>Sort by Date:</label>
              <select
                name="createdAt"
                value={filterValues.createdAt}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All</option>
                <option value="descending">Latest</option>
                <option value="ascending">Oldest</option>
              </select>
            </div>
            {/* Listed/Unlisted B2B */}
            <div className="mb-3">
              <label className={labelClasses}>Products:</label>
              <select
                name="isB2B"
                value={filterValues.isB2B}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All Products</option>
                <option value="true">B2B Only</option>
                <option value="false">Exclude B2B</option>
              </select>
            </div>
            {/* Product Status */}
            <div className="mb-3">
              <label className={labelClasses}>Product Status:</label>
              <select
                name="isActive"
                value={filterValues.isActive}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All Products</option>
                <option value="true">Listed</option>
                <option value="false">Unlisted</option>
              </select>
            </div>
            {/* Stock In/Out */}
            <div className="mb-3">
              <label className={labelClasses}>Stock Status:</label>
              <select
                name="stock"
                value={filterValues.stock}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All</option>
                <option value="descending">In Stock</option>
                <option value="ascending">Out of Stock</option>
              </select>
            </div>
            {/* Most Selling */}
            <div className="mb-3">
              <label className={labelClasses}>Sales Ranking:</label>
              <select
                name="sold"
                value={filterValues.sold}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All Products</option>
                <option value="descending">Top Selling</option>
                <option value="ascending">Least Selling</option>
              </select>
            </div>
            {/* Categories */}
            <div className="mb-3">
              <label className={labelClasses}>Categoris:</label>
              <select
                name="categoryId"
                value={filterValues.categoryId}
                onChange={handleSelect}
                className={selectClasses}
              >
                <option value="">All Categories</option>
                {categories.length
                  ? categories.map((cat) => (
                      <option value={cat?._id} key={cat?._id}>
                        {cat?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>

          <div className="absolute inset-x-4 bottom-2 flex gap-2 justify-between">
            {/* Clear Button */}
            <button
              onClick={() => {
                resetFilters();
                handleFilterApply(true);
                onClose();
              }}
              className="bg-gray-500 dark:bg-gray-600 text-center text-white-A700  px-4 py-2 rounded-lg w-full mt-3 hover:bg-gray-900 dark:hover:bg-gray-700"
            >
              Clear
            </button>

            {/* Apply Filters Button */}
            <button
              onClick={() => {
                handleFilterApply(true);
                onClose();
              }}
              className="bg-gray-800 dark:bg-gray-700 text-center text-white-A700  px-4 py-2 rounded-lg w-full mt-3 hover:bg-gray-900 dark:hover:bg-gray-600"
            >
              Apply
            </button>
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default FilterDrawer;
