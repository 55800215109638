import React, { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Star } from "lucide-react";
import convertTodateString from "../../../utils/convertTodateString";
import {
  capitalizeInitial,
  getLastOrderStatus,
  getStatusColor,
  getTruncated,
} from "../../../utils";

const OrderList = ({
  _id,
  productId,
  productName,
  quantity,
  price,
  status,
  thumbnail,
  orderId,
}) => {
  const navigate = useNavigate();
  const lastOrderStatus = getLastOrderStatus(status);
  const handleClick = () =>
    navigate(`/orders/${orderId}?productId=${productId}`, { replace: true });
  return (
    <div
      key={_id}
      className="flex sm:flex-col justify-between 
       lg:items-center gap-4 lg:gap-5 bg-transparent hover:cursor-pointer
        rounded-lg shadow-md p-4 border border-gray-100 "
      onClick={handleClick}
    >
      <div div className="flex sm:flex-col gap-2 items-center w-full">
        <img
          src={thumbnail}
          alt={productName}
          className="h-24 w-24 object-contain rounded-md"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/product/${productId}`);
          }}
        />
        <p className="text-black font-semibold text-md md:self-start">
          {productName?.length > 25
            ? getTruncated(productName, 25)
            : productName}
        </p>
      </div>
      <p className="text-black text-sm">
        <span className="font-medium">Quantity:</span> {quantity}
      </p>
      <p className="text-black  ">
        <span className="font-medium">Price:</span> ${price}
      </p>
      <div className=" flex flex-col gap-2 w-full lg:w-1/2">
        <p className="text-gray-500">
          Date of Purchase: {convertTodateString(status?.initiated?.time)}
        </p>
        <p
          className="font-semibold"
          style={{ color: getStatusColor(lastOrderStatus?.name) }}
        >
          {capitalizeInitial(lastOrderStatus?.name) +
            lastOrderStatus?.name.slice(1)}
        </p>
        {status?.delivered?.status && (
          <p
            className="inline-flex gap-2 items-center text-blue-500 font-medium"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/orders/${orderId}?productId=${productId}#rating`, {
                replace: true,
              });
            }}
          >
            Rate & Review Product <Star className="size-5 fill-blue-500" />
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(OrderList);
