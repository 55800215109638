import { useState } from "react";

const useFilter = (initialFilterValues) => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const handleFiltersSelection = (newFilters) => {
    setFilterValues((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const resetFilters = () => {
    setFilterValues({});
  };

  return {
    filterValues,
    setFilterValues,
    handleFiltersSelection,
    resetFilters,
  };
};

export default useFilter;
