import React, { useState } from "react";
import { Text, Heading, Img } from "../../UserComponents";
import { FullHeartSVG, HeartOutlineSVG } from "../../UserComponents/svg";
import { useNavigate } from "react-router-dom";
import showToast from "../../../utils/showToast";
import { useSelector } from "react-redux";
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from "../../../utils/api/product";
import { getTruncated } from "../../../utils";

export default function ProductCard({
  _id,
  name,
  thumbnail,
  price,
  averageRating,
  actualPrice,
  isProductInWishlist,
  className = "",
  showWishlistIcon = true,
}) {
  const [wishlistStatus, setWishlistStatus] = useState(isProductInWishlist);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleWishlistChange = () => {
    setIsSubmitting(true);
    // If wishliststatus is true, then remove the item from wishlist or add to wishlist
    if (wishlistStatus) {
      removeItemFromWishlist(_id)
        .then(() => {
          showToast("Item removed from wishlist");
          setWishlistStatus(!wishlistStatus);
        })
        .catch(({ response }) => showToast(response.data.message, "error"))
        .finally(() => setIsSubmitting(false));
      return;
    }

    addItemToWishlist(_id)
      .then(() => {
        showToast("Item Added to wishlist");
        setWishlistStatus(!wishlistStatus);
      })
      .catch((e) => showToast(e.message, "error"))
      .finally(() => setIsSubmitting(false));
  };
  const handleClick = () => {
    navigate(`/product/${_id}`);
  };
  return (
    <div className={`${className} max-w-sm flex flex-col gap-5 mx-3 `}>
      <div className="relative overflow-hidden rounded-[16px] bg-gray-50 ">
        <Img
          src={thumbnail}
          alt={`${name} Image`}
          className="h-full w-full rounded-xl object-cover hover:cursor-pointer"
          onClick={handleClick}
        />
        {user?.isAuthenticated && user.role !== "admin" && showWishlistIcon && (
          <button
            onClick={handleWishlistChange}
            disabled={isSubmitting}
            className="absolute right-[12.00px] top-[12.00px] m-auto p-1 bg-white-A700 rounded-full disabled:cursor-not-allowed disabled:opacity-50"
          >
            {wishlistStatus ? (
              <FullHeartSVG className="size-6" />
            ) : (
              <HeartOutlineSVG className="size-6" />
            )}
          </button>
        )}
      </div>
      <div className="flex items-start justify-evenly gap-5 self-stretch">
        <div className="flex flex-col items-start gap-3.5">
          <div className="flex flex-col items-start">
            <div
              className="flex self-center hover:cursor-pointer"
              onClick={handleClick}
            >
              <Heading as="h6">
                {name?.length > 25 ? getTruncated(name, 25) : name}
              </Heading>
            </div>
            {/* <Text size="s" as="p">
              {category}
            </Text> */}
          </div>
          <div className="flex gap-1">
            <Img
              src="/images/star.svg"
              alt="rating image"
              className="h-[20px] w-[20px]"
            />
            <div className="flex">
              <Text size="s" as="p">
                {averageRating > 0 ? averageRating.toFixed(1) : 4.5}
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div className="flex self-start">
            <Heading as="h6">${price}</Heading>
          </div>
          {price !== actualPrice && (
            <Text size="s" as="p" className="line-through">
              {actualPrice}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
