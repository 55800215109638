import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FeedbackCard from "../../../components/AdminComponents/Feedback/FeedbackCard";
import { USER_URL } from "../../../constants";
import { usePageTitle } from "../../../context/PageTitle";
import useFetchApi from "../../../Hooks/useFetchApi";
import NotFoundPage from "../../../components/Error/404Page";
import Loader from "../../../components/UserComponents/Loader";

const Index = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const {
    data: reviews,
    isLoading,
    error,
  } = useFetchApi({
    url: USER_URL + `/feedback?productId=${productId}`,
  });

  useEffect(() => setPageTitle("Ratings"), []);

  if (error) {
    return <NotFoundPage />;
  }
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="flex h-full">
        <div className={`p-8 `}>
          <div className="grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4 mt-5 p-2 ">
            {reviews.length ? (
              <>
                {reviews.map((review, index) => (
                  <FeedbackCard key={index} {...review} />
                ))}
              </>
            ) : (
              <h2 className="text-lg text-gray-500 text-center">
                No reviews found
              </h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
