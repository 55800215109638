import { useState } from "react";
import OrderActionModal from "./dialogue/OrderActionModal";
import { checkReturnEligibility, getLastOrderStatus } from "../../../utils";
const cancellationNotAllowed = ["outForDelivery", "delivered", "retur"];

const ReturnOrCancel = ({
  orderId,
  productId,
  status,
  setOrder,
  orderDate,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const { name } = getLastOrderStatus(status);
  const handleButtonClick = (type) => {
    setActionType(type);
    setShowModal(true);
  };

  return (
    <div className="flex gap-4 mt-7">
      {name === "delivered" && checkReturnEligibility(orderDate, 7) && (
        <button
          onClick={() => handleButtonClick("return")}
          className="bg-blue-100 text-blue-600 font-medium text-sm px-4 py-2 rounded-lg hover:bg-blue-200 transition duration-200"
        >
          Return Item
        </button>
      )}
      {name !== "cancelled" && !cancellationNotAllowed.includes(name) && (
        <button
          onClick={() => handleButtonClick("cancel")}
          className="bg-red-100 text-red-600 font-medium text-sm px-4 py-2 rounded-lg hover:bg-red-200 transition duration-200"
        >
          Cancel order
        </button>
      )}

      {/* Modal */}
      {showModal && (
        <OrderActionModal
          handlevisibilty={() => setShowModal(false)}
          actionType={actionType}
          orderId={orderId}
          productId={productId}
          setOrder={setOrder}
        />
      )}
    </div>
  );
};

export default ReturnOrCancel;
