import React, { useEffect, useState } from "react";
import useFetchApi from "../../../Hooks/useFetchApi";
import Skeleton from "../../../components/UserComponents/Skeleton";
import { Heading } from "../../../components/UserComponents";
import ProductCard from "../../../components/UserComponents/ProductCard";
import { USER_URL } from "../../../constants";
import { getProductsWithWishlistStatus } from "../../../utils/api/products";
import { useParams } from "react-router-dom";

const RecommendedProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { productId } = useParams();
  const { data } = useFetchApi({
    url: USER_URL + "/products",
    limit: 4,
  });
  useEffect(() => {
    setLoading(true);
    async function getUpdatedProducts() {
      try {
        const updatedProducts = await getProductsWithWishlistStatus(data);
        setProducts(updatedProducts.filter((p) => p._id !== productId));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    if (data.length) getUpdatedProducts();
  }, [data, productId]);
  return (
    <>
      {isLoading && (
        <div className="grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-6 mx-2.5">
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} />
          ))}
        </div>
      )}
      {products.length ? (
        <div className="flex flex-col items-start gap-7">
          <Heading size="lg" as="h1">
            Recommended products
          </Heading>
          <div className="grid grid-cols-4 sm:grid-cols-2 lg:grid-cols-4 gap-6 mx-2.5">
            {products.map((product) => (
              <ProductCard
                {...product}
                key={product._id}
                className="md:w-full md:gap-5"
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecommendedProducts;
