import React, { useEffect } from "react";
import OfferList from "../../../components/AdminComponents/offers";
import { usePageTitle } from "../../../context/PageTitle";

const Offers = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Offers"), []);
  return (
    <div className="flex h-full">
      <OfferList />
    </div>
  );
};

export default Offers;
