import { createContext, useContext, useState } from "react";

const AuthExpirationModalContext = createContext({
  isModalOpen: false,
  setModalOpen: null,
});
AuthExpirationModalContext.displayName = "AuthExpirationModalContext";

export const useAuthExpirationModal = () =>
  useContext(AuthExpirationModalContext);

const AuthExpirationModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <AuthExpirationModalContext.Provider value={{ isModalOpen, setModalOpen }}>
      {children}
    </AuthExpirationModalContext.Provider>
  );
};

export default AuthExpirationModalProvider;
