import { useRef } from "react";
import { FormikInput } from "../../../components/UserComponents/Input/FormikInput";
import { Text } from "../../../components/UserComponents/Text";
import useDisableBodyScroll from "../../../Hooks/useDisableBodyScroll";
import useOutsideClickAlerter from "../../../Hooks/OutsideClickAlerter";
import { X } from "lucide-react";
import { useFormik } from "formik";
import { validateRFQ } from "../../../utils/validations/validations";
import { motion } from "framer-motion";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import showToast from "../../../utils/showToast";
import axiosInstance from "../../../utils/api/axiosInstance";
import { USER_URL } from "../../../constants";
const QuoteForm = ({ productData, handlevisibilty, setMessage }) => {
  const ref = useRef(null);
  const formik = useFormik({
    validateOnBlur: false,
    // validateOnChange: false,
    initialValues: {
      productName: productData?.name || "product name",
      productId: productData?._id || "12345",
      productImage: productData?.thumbnail || "",
      quantity: "",
      date: "",
      instruction: "",
      address: "",
      phone: "",
      budget: "",
      urgency: "relaxed",
      country: "",
    },
    validationSchema: validateRFQ,
    onSubmit: (values) => {
      console.log(values);
      axiosInstance
        .post(USER_URL + "/request-quote", values)
        .then(() => {
          handlevisibilty();
          setMessage(
            "Your quote has been submitted successfully. The seller will reach out if they accept your quote."
          );
        })
        .catch((e) => showToast(e?.response?.data?.message, "error"))
        .finally(() => formik.setSubmitting(false));
    },
  });
  useOutsideClickAlerter(ref, handlevisibilty);
  useDisableBodyScroll(true);

  return (
    <div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex h-full bg-gray-500 bg-opacity-50 overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0  max-h-full"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.2 }}
        className="relative p-4 w-full max-w-xl my-auto"
      >
        <div
          className="relative bg-white-A700 rounded-lg shadow  dark:bg-dark max-h-[580px] px-2  overflow-y-auto scrollbar-hide"
          ref={ref}
        >
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white-A700">
              Please Fill out the details
            </h3>
            <button
              type="button"
              onClick={handlevisibilty}
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="authentication-modal"
            >
              <X />
            </button>
          </div>
          <form
            className="p-4 grid grid-cols-2 gap-4"
            onSubmit={formik.handleSubmit}
          >
            {/* Product Name */}
            <div className="space-y-1 col-span-1 sm:col-span-2">
              <FormikInput
                label="Product Name"
                name="productName"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Enter product name"
                disabled
                {...formik.getFieldProps("productName")}
              />
            </div>

            {/* Quantity */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Quantity"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Enter quantity"
                type="number"
                {...formik.getFieldProps("quantity")}
              />
              {formik.errors.quantity && (
                <Text className="text-red-500 text-sm">
                  {formik.errors.quantity}
                </Text>
              )}
            </div>

            {/* Preferred Delivery Date */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Preferred Delivery Date"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Select delivery date"
                type="date"
                min={new Date().toISOString().substring(0, 10)}
                {...formik.getFieldProps("date")}
              />
              {formik.errors.date && (
                <Text className="text-red-500 text-sm">
                  {formik.errors.date}
                </Text>
              )}
            </div>

            {/* Additional Instructions */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Additional Instructions (Optional)"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Any special instructions"
                {...formik.getFieldProps("instruction")}
              />
            </div>

            {/* Shipping Address */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Shipping Address"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Enter shipping address"
                {...formik.getFieldProps("address")}
              />
              {formik.errors.address && (
                <Text className="text-red-500 text-sm">
                  {formik.errors.address}
                </Text>
              )}
            </div>

            {/* Phone Number */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Phone Number"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Enter phone number"
                {...formik.getFieldProps("phone")}
              />
              {formik.errors.phone && (
                <Text className="text-red-500 text-sm">
                  {formik.errors.phone}
                </Text>
              )}
            </div>

            {/* Budget/Price Expectations */}
            <div className="space-y-1 w-full col-span-1 sm:col-span-2">
              <FormikInput
                label="Budget/Price Expectations"
                className="placeholder:text-gray-400 placeholder:text-[14px]"
                placeholder="Enter your budget"
                type="number"
                {...formik.getFieldProps("budget")}
              />
              {formik.errors.budget && (
                <Text className="text-red-500 text-sm">
                  {formik.errors.budget}
                </Text>
              )}
            </div>

            <div className="mb-3 col-span-1 sm:col-span-2">
              <label className="block text-gray-700 dark:text-gray-300 font-medium text-sm mb-1">
                Request Urgency:
              </label>
              <select
                name="Request Urgency"
                value={formik.values.urgency}
                onChange={(e) =>
                  formik.setFieldValue("urgency", e.target.value)
                }
                className="w-full p-2 border border-gray-300 focus:border-gray-300
   dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700
   text-gray-900 dark:text-gray-300 text-sm"
              >
                <option value="relaxed">Normal</option>
                <option value="immediate">Urgent</option>
                <option value="flexible">Flexible</option>
              </select>
            </div>
            <div className="col-span-2 ">
              <CountrySelect
                onChange={(e) => formik.setFieldValue("country", e.name)}
                placeHolder="Select Country"
                value={formik.values.country}
                style={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
              />
              {formik.errors.country && (
                <Text className="text-red-500 text-sm ">
                  {formik.errors.country}
                </Text>
              )}
            </div>
            <div className="flex justify-center col-span-2 ">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className=" py-3 px-4 text-blue-600 font-medium bg-blue-200 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:cursor-not-allowed"
              >
                {formik.isSubmitting ? "Submiting.." : "Submit Request"}
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default QuoteForm;
