import { useRef, useState } from "react";
import { Text } from "../../Text";
import useDisableBodyScroll from "../../../../Hooks/useDisableBodyScroll";
import useOutsideClickAlerter from "../../../../Hooks/OutsideClickAlerter";
import useImageCropping from "../../../../Hooks/useImageCropping";
import { LoaderCircle, Upload, X } from "lucide-react";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import { getAllowedImageFormats } from "../../../../utils";
import { MIN_TEXT_AREA_HEIGHT, USER_URL } from "../../../../constants";
import useDynamicTextArea from "../../../../Hooks/useDynamicTextArea";
import axiosInstance from "../../../../utils/api/axiosInstance";
import showToast from "../../../../utils/showToast";

const OrderActionModal = ({
  actionType,
  orderId,
  productId,
  handlevisibilty,
  setOrder,
}) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const ref = useRef(null);
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      reason: "",
      images: [],
    },
    onSubmit: (values) => {
      let toastMessage;
      if (actionType === "cancel") {
        axiosInstance
          .delete(USER_URL + `/order/${orderId}`, {
            data: { ...values, productId },
          })
          .then(() => showToast("Order Canceled"))
          .catch((e) => showToast(e?.response?.data?.message, "error"))
          .finally(() => formik.setSubmitting(false));
      } else
        axiosInstance
          .patch(USER_URL + `/order/return/${orderId}`, {
            ...values,
            productId,
          })
          .then(() =>
            showToast(
              "Refund initiated, amount will be refunded within 3-4 Business days"
            )
          )
          .catch((e) => showToast(e?.response?.data?.message, "error"))
          .finally(() => formik.setSubmitting(false));
      // ? (toastMessage = "")
      // : (toastMessage =
      //     "Refund initiated, amount will be refunded within 3-4 Business days");
    },
  });
  useOutsideClickAlerter(ref, handlevisibilty);
  useDisableBodyScroll(true);
  const { textareaRef } = useDynamicTextArea(formik.values.reason);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imageURLs = files.map((file) => URL.createObjectURL(file));
    if (imageURLs.length > 5) {
      formik.setFieldError("images", "Maximum 5 images  allowed");
      return;
    }
    formik.setFieldError("images", undefined);
    if (imageURLs.length) setSelectedImages(imageURLs);
  };

  return (
    <div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="flex h-full bg-gray-500 bg-opacity-50 overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0  max-h-full"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.2 }}
        className="relative p-4 w-full max-w-md my-auto"
      >
        <div
          className="relative bg-white-A700 rounded-lg shadow  dark:bg-dark max-h-[580px] px-2  overflow-y-auto scrollbar-hide"
          ref={ref}
        >
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white-A700">
              {actionType === "return" ? "Return Item" : "Cancel Order"}
            </h3>
            <button
              type="button"
              onClick={handlevisibilty}
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="authentication-modal"
            >
              <X />
            </button>
          </div>
          <form
            className="p-4 grid grid-cols-2 gap-4"
            onSubmit={formik.handleSubmit}
          >
            <div className="col-span-2 space-y-1">
              <label
                htmlFor="reason"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-A700"
              >
                Reason
              </label>
              <textarea
                ref={textareaRef}
                style={{
                  minHeight: MIN_TEXT_AREA_HEIGHT,
                  resize: "none",
                }}
                className="block p-2.5 w-full text-sm text-gray-900 scrollbar-hide bg-gray-50 rounded-lg !border !outline-none border-gray-300  dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white-A700 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write reason here"
                {...formik.getFieldProps("reason")}
              />

              {formik.errors.reason && (
                <Text className="text-red-500" size="s">
                  {formik.errors.reason}
                </Text>
              )}
            </div>
            {actionType === "return" ? (
              <div className="col-span-2">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-A700"
                  htmlFor="file_input"
                >
                  Upload file
                </label>
                <div className="flex justify-between items-center gap-2">
                  <div>
                    <input
                      className="flex h-9 w-full rounded-xl px-3 py-1 focus:file:outline-none text-sm transition-colors file:border-0 file:bg-transparent file:text-foreground file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                      type="file"
                      multiple
                      onChange={handleImageChange}
                    />
                    <Text className="text-red-500" size="s">
                      {formik.errors.images}
                    </Text>
                  </div>
                </div>

                <p className="inline-flex flex-col gap-1  text-sm text-gray-500 dark:text-gray-300">
                  {getAllowedImageFormats()} (MAX. 5){" "}
                  <span className="text-[12px] ">
                    WEBP is recomended for faster image load
                  </span>
                </p>

                <div className="flex gap-2 items-center mt-2 flex-wrap">
                  {selectedImages.length ? (
                    <>
                      {selectedImages.map((url, i) => (
                        <div className="size-28" key={i}>
                          <img
                            src={url}
                            className="w-full rounded-md object-contain"
                          />
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="flex justify-center col-span-2 ">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className=" py-2 px-4 text-blue-600 font-medium bg-blue-200 rounded-full hover:bg-blue-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default OrderActionModal;
