import React, { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "../../../components/AdminComponents/card/MiniStatistics";
import IconBox from "../../../components/AdminComponents/icons/IconBox";
import { MdAttachMoney, MdBarChart, MdSell } from "react-icons/md";
import { PiPackage } from "react-icons/pi";
import { AiFillProduct } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import DailyTraffic from "../../../components/AdminComponents/Dashboard/DailyTraffic";
import PieCard from "../../../components/AdminComponents/Dashboard/PieCard";
import TotalSales from "../../../components/AdminComponents/Dashboard/TotalSales";
import WeeklyRevenue from "../../../components/AdminComponents/Dashboard/WeeklyRevenue";
import { ADMIN_URL, darkCardBg } from "../../../constants";
import axiosInstance from "../../../utils/api/axiosInstance";
import Loader from "../../../components/UserComponents/Loader";
import {
  calculatePercentageDifference,
  formatAmount,
  getMonthlyData,
} from "../../../utils";
import { usePageTitle } from "../../../context/PageTitle";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Dashboard"), []);

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("gray.50", darkCardBg);
  useEffect(() => {
    setIsLoading(true);
    axiosInstance(ADMIN_URL + "/dashboard")
      .then(({ data }) => {
        setData(data.data);
        setIsLoading(false);
      })
      .catch((e) => "");
  }, []);

  const ordersGrowth = useMemo(() => {
    let current, previous;
    if (!data?.order) return;
    const { ordersPerMonth } = data?.order;
    if (ordersPerMonth.length) {
      current = ordersPerMonth.at(-1)?.count; // Last month
      const secondLastItem = ordersPerMonth.at(-2); // Month before last
      previous = secondLastItem ? secondLastItem?.count : 0;
    }

    return calculatePercentageDifference(previous, current);
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <Box pt={{ base: "130px", md: "80px", xl: "40px" }} p="5">
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="15px"
          mb="10px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdBarChart}
                    textColor={brandColor}
                  />
                }
              />
            }
            name="Total Earnings"
            value={
              "$" + formatAmount(data?.order?.totalEarnings?.totalEarning ?? 0)
            }
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdAttachMoney}
                    textColor={brandColor}
                  />
                }
              />
            }
            name="This month Earning"
            value={
              "$" +
              formatAmount(
                data?.order?.revenuePerMonth.at(-1)?.totalRevenue ?? 0
              )
            }
          />
          <MiniStatistics
            growth={ordersGrowth && ordersGrowth}
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={PiPackage}
                    textColor={brandColor}
                  />
                }
              />
            }
            name="Orders this month "
            value={data?.order?.newOrdersThisMonth ?? 0}
          />
          <MiniStatistics
            // endContent={
            //   <Flex me="-16px" mt="10px">
            //     <FormLabel htmlFor="balance">
            //       <Avatar src={Usa} />
            //     </FormLabel>
            //     <Select
            //       id="balance"
            //       variant="mini"
            //       mt="5px"
            //       me="0px"
            //       defaultValue="usd"
            //     >
            //       <option value="usd">USD</option>
            //       <option value="eur">EUR</option>
            //       <option value="gba">GBA</option>
            //     </Select>
            //   </Flex>
            // }
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={FaUsers} textColor={brandColor} />
                }
              />
            }
            name="Total users"
            value={data?.user?.totalUsers ?? 0}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={AiFillProduct}
                    textColor={brandColor}
                  />
                }
              />
            }
            name="Active Products"
            value={data?.product?.totalProducts ?? 0}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdSell} textColor={brandColor} />
                }
              />
            }
            name="Total Sold"
            value={data?.product?.totalSale ?? 0}
          />
        </SimpleGrid>

        <SimpleGrid columns={{ base: 1 }} gap="20px" my="20px">
          <TotalSales data={data?.order} />
          {/* <WeeklyRevenue /> */}
        </SimpleGrid>

        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px"> */}
        {/* <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          /> */}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic
            data={getMonthlyData(data?.user?.usersPerMonth || [], "count")}
          />
          <PieCard data={data?.product} />
        </SimpleGrid>
        {/* </SimpleGrid> */}
        {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
          />
          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
            <Tasks />
            <MiniCalendar h="100%" minW="100%" selectRange={false} />
          </SimpleGrid>
        </SimpleGrid> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
