import React, { useEffect, useMemo } from "react";

import { Heading, Text } from "../../../components/UserComponents";
import CheckoutProduct from "../../../components/UserComponents/CheckoutProduct";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import showToast from "../../../utils/showToast";
import {
  loadCartFromServer,
  removeFromCart,
} from "../../../redux/slices/CartSlice";
import EmptyCart from "../../../components/UserComponents/EmptyCart";
import { removeItemFromCart } from "../../../utils/api/cart";

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);

  const orderTotal = useMemo(() => {
    return cart.reduce((acc, curr) => {
      const subTotal = curr.price * curr.quantity;
      return acc + subTotal;
    }, 0);
  }, [cart]);

  const isEveryProductStockAvailable = useMemo(
    () => cart.every((item) => item.quantity < item.stock),
    [cart]
  );
  const handleRemoveFromCart = (_id) => {
    removeItemFromCart(_id)
      .then(() => {
        showToast("Product removed from cart successfully");
        dispatch(removeFromCart({ _id }));
      })
      .catch((e) => showToast(e.message, "error"));
  };

  const handleCheckout = () => {
    if (!isEveryProductStockAvailable) {
      showToast(
        "Please remove the out of stock item before checkout to proceed",
        "dark"
      );
      return;
    }
    navigate("/checkout");
  };
  return (
    <>
      {/* order summary section */}
      <div className="max-w-3xl sm:mx-10 mx-auto  mt-5">
        <Heading size="md" as="h4">
          Your Cart
        </Heading>
        <div className="flex flex-col sm:items-center justify-center gap-2 mb-2">
          {cart.length ? (
            <>
              {cart.map((item) => (
                <CheckoutProduct
                  {...item}
                  className="flex-1"
                  key={item._id}
                  handleRemoveFromCart={handleRemoveFromCart}
                />
              ))}
            </>
          ) : null}
        </div>
      </div>

      {/* order total section */}
      {cart.length ? (
        <>
          <div className="max-w-3xl mx-auto border-t border-solid border-gray-200 pt-6 sm:pt-5">
            <div className="flex flex-col  gap-[7px]">
              {/*   <div className="flex justify-between gap-5">
                <div className="flex">
                  <Text as="p">Shipping estimate</Text>
                </div>
                <div className="flex pt-px">
                  <Text as="p">$5.00</Text>
                </div>
              </div>
              <div className="flex justify-between gap-5">
                <Text as="p">Tax estimate</Text>
                <div className="flex">
                  <Text as="p">$24.90</Text>
                </div>
              </div> */}
            </div>
            <div className="flex flex-wrap justify-between gap-5">
              <Heading as="h6">Order total</Heading>
              <Heading as="h6">${orderTotal}</Heading>
            </div>
          </div>

          <div className="flex sm:flex-col items-center justify-center gap-4 sm:gap-4 mt-4">
            <button
              className="sm:order-2 bg-gray-900 text-white-A700 px-3 text-sm rounded-[26px]  py-2 font-medium sm:px-5 cursor-pointer"
              onClick={() => navigate("/shop")}
            >
              Continue shopping
            </button>
            <button
              className="sm:order-1 bg-gray-900 text-white-A700 px-3 text-sm rounded-[26px]  py-2 font-medium sm:px-5 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleCheckout}
            >
              Checkout
            </button>
          </div>
        </>
      ) : (
        <EmptyCart />
      )}
    </>
  );
}

export default Cart;
