import React, { useEffect, useState } from "react";
import axiosInstance, { axiosPublicInstance } from "../utils/api/axiosInstance";
import { usePaginateState } from "./usePagination";

export default function useFetchApi({
  url,
  limit = 10,
  isProtectedApi = true,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const {
    currentPage,
    itemsPerPage,
    pageSize,
    setItemsPerPage,
    setPageSize,
    handleOnPageChange,
  } = usePaginateState(limit);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      let instance = axiosInstance;
      !isProtectedApi && (instance = axiosPublicInstance);

      try {
        const { data } = await instance(url, {
          params: {
            ...filters,
            pageNum: currentPage,
            limit: itemsPerPage,
          },
        });
        setData(data.data);
        setPageSize(data?.count || 0);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    console.log(
      "fetching data",
      url,
      filters,
      currentPage,
      itemsPerPage,
      pageSize
    );
  }, [url, isProtectedApi, filters, currentPage, itemsPerPage]);

  return {
    data,
    isLoading,
    error,
    setData,
    filters,
    setFilters,
    currentPage,
    itemsPerPage,
    pageSize,
    handleOnPageChange,
  };
}
