import React, { useState, useEffect } from "react";
import { Heading, Text, Button, Img } from "../../../components/UserComponents";
import { useNavigate, useParams } from "react-router-dom";
import {
  addItemToWishlist,
  getProduct,
  removeItemFromWishlist,
} from "../../../utils/api/product";
import showToast from "../../../utils/showToast";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/slices/CartSlice";
import axiosInstance, {
  axiosPublicInstance,
} from "../../../utils/api/axiosInstance";
import { USER_URL } from "../../../constants";
import UserReviews from "../../../components/UserComponents/Reviews/Reviews";
import {
  FullHeartSVG,
  HeartOutlineSVG,
} from "../../../components/UserComponents/svg";
import { getProductsWithWishlistStatus } from "../../../utils/api/products";
import { getTruncated } from "../../../utils";
import SEOComponent from "../../../components/UserComponents/SEOComponent";
import { Star } from "lucide-react";
import ProductImages from "./ProductImages";
import B2BContactSection from "./B2BContactSection";
import NotFoundPage from "../../../components/Error/404Page";
import RecommendedProducts from "./RecommendedProducts";
import LoadingSkeleton from "./LoadingSkeleton";

export default function ProductPage() {
  const [product, setProduct] = useState(null);
  const [showB2BContact, setShowB2BContact] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [hasProductInCart, setHasProductInCart] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wishlistStatus, setWishlistStatus] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [description, setDescription] = useState({
    readMore: false,
    text: null,
  });
  const [error, setError] = useState(null);
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (cart && product) {
      const isProductAlreadyInCart = cart.length
        ? cart.find((p) => p?._id === product?._id)
        : null;
      setHasProductInCart(isProductAlreadyInCart ? true : false);
    }
  }, [cart, product]);

  useEffect(() => {
    const getProductData = async () => {
      try {
        const { data } = await getProduct(productId);

        setShowB2BContact(user?.isB2B && data?.isB2B);
        if (user.isAuthenticated && user.role == "user") {
          const [wishlistUpdatedProduct] = await getProductsWithWishlistStatus([
            data,
          ]);
          setWishlistStatus(wishlistUpdatedProduct.isProductInWishlist);
        }
        setProduct(data);

        setDescription({
          readMore: false,
          text: getTruncated(data?.description, 300),
        });
        setSelectedImage(data?.images[0]);
        const { data: reviews } = await axiosPublicInstance(
          USER_URL + `/feedback?productId=${productId}`
        );
        setReviews(reviews.data);
      } catch (error) {
        setError(error);
        // showToast(error.message, "dark");
      }
    };
    getProductData();
  }, [productId, user]);

  const handleSelectImage = (image) => setSelectedImage(image);

  const handleWishlistChange = () => {
    const updateWishlist = !wishlistStatus;
    setWishlistStatus(updateWishlist);
    if (updateWishlist) {
      addItemToWishlist(product?._id)
        .then(() => showToast("Added to your wishlist"))
        .catch((e) => showToast(e.message, "error"));
    } else {
      removeItemFromWishlist(product?._id)
        .then(() => showToast("Removed from your wishlist"))
        .catch((e) => showToast(e.message, "error"));
    }
  };

  const handleAddtoCart = () => {
    if (!user?.isAuthenticated || user.role !== "user") {
      navigate(`/login?redirect=/product/${product?._id}`);
      return;
    }
    if (hasProductInCart) {
      navigate("/cart");
      return;
    }
    if (cart.length >= 10) {
      showToast(
        "Sorry, you can only add up to 10 product to your cart.",
        "dark"
      );
      return;
    }
    const payload = {
      _id: product?._id,
      name: product?.name,
      thumbnail: product?.images[0],
      stock: product?.stock,
      quantity: 1,
      price: product?.price,
    };
    setIsSubmitting(true);
    axiosInstance
      .post(USER_URL + "/cart", { productId: product?._id })
      .then(() => {
        dispatch(addToCart(payload));
        showToast("Item added to cart successfully");
      })
      .catch((e) => showToast(e.message, "error"))
      .finally(() => setIsSubmitting(false));
  };

  if (error) return <NotFoundPage />;

  return (
    <>
      <SEOComponent
        title={product?.name + "On best price"}
        description={getTruncated(product?.description || "", 25)}
      />
      <div className="w-full bg-white-A700">
        {/* header section */}

        <div className="flex flex-col items-center pb-6 pt-10 md:pb-5 sm:py-5 lg:px-5">
          <div className="container-xs flex flex-col gap-8  md:p-5">
            {/* Product detials */}
            {!product ? (
              <LoadingSkeleton />
            ) : (
              <div className="flex flex-col lg:flex-row gap-2 lg:gap-0 items-center  w-full">
                {/* product Images section */}
                <ProductImages
                  {...product}
                  selectedImage={selectedImage}
                  handleSelectImage={handleSelectImage}
                />

                <div className="flex w-full flex-col self-start  lg:min-h-[450px] scrollbar-hide">
                  {/* Product price and add to cart info */}
                  <div className="flex  w-full flex-col gap-[31px] rounded-[16px] border border-solid border-gray-200 px-[30px] pb-[33px] pt-[30px] md:w-full sm:p-5">
                    <div className="flex flex-col items-start justify-between gap-5">
                      <div className="flex flex-col gap-1 items-center">
                        <div className="flex items-center gap-1.5">
                          <Heading
                            as="h5"
                            className="flex items-center gap-1 !text-blue_gray-700"
                          >
                            <Star className="size-4 fill-yellow-400 text-yellow-400" />
                            4.9
                          </Heading>

                          {reviews.length ? (
                            <a href="#reviews">
                              <Text as="p" className="!font-medium underline">
                                {" "}
                                {reviews.length} reviews
                              </Text>
                            </a>
                          ) : null}
                        </div>
                      </div>
                      {/* Product info */}
                      <div className="flex w-[91%] flex-col gap-[9px] md:w-full">
                        <div className="flex">
                          <Heading size="md" as="h3">
                            {product?.name}
                          </Heading>
                        </div>
                        <div className="flex flex-1">
                          <Text as="p" className="leading-6 text-sm">
                            {!description.readMore ? (
                              <>
                                {description.text}
                                <span
                                  className="ml-2 text-sm text-gray-400 cursor-pointer"
                                  onClick={() =>
                                    setDescription({
                                      readMore: true,
                                      text: product.description,
                                    })
                                  }
                                >
                                  Read more
                                </span>
                              </>
                            ) : (
                              product.description
                            )}
                          </Text>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-8">
                      {showB2BContact ? (
                        <B2BContactSection {...product} />
                      ) : (
                        <div className="flex sm:flex-col items-center justify-between gap-5">
                          {product.stock > 0 && (
                            <p
                              className={`text-sm p-2 rounded-full px-3 text-white-A700 font-medium ${
                                product.stock >= 5
                                  ? "bg-green-500"
                                  : product.stock >= 3
                                  ? "bg-yellow-500"
                                  : "bg-red-500"
                              }`}
                            >
                              {product.stock >= 5
                                ? "In stock"
                                : product.stock >= 3
                                ? `Hurry, only ${product.stock} left!`
                                : `Hurry, only ${product.stock} left!`}
                            </p>
                          )}

                          {/* Price details */}
                          <div className="flex flex-1 justify-end">
                            <Heading size="md" as="h4">
                              ${product?.price}
                            </Heading>
                            {product?.price !== product?.actualPrice && (
                              <Text
                                size="s"
                                as="p"
                                className="!font-medium line-through"
                              >
                                ${product?.actualPrice}
                              </Text>
                            )}
                          </div>
                          <div className="flex gap-2 items-center sm:px-4 p-1">
                            {user?.isAuthenticated && user.role == "user" && (
                              <button
                                onClick={handleWishlistChange}
                                disabled={isSubmitting}
                                className="p-1 bg-white-A700 rounded-full disabled:cursor-not-allowed disabled:opacity-50"
                              >
                                {wishlistStatus ? (
                                  <FullHeartSVG className="size-8" />
                                ) : (
                                  <HeartOutlineSVG className="size-8" />
                                )}
                              </button>
                            )}
                            {product?.stock < 1 ? (
                              <button
                                className="bg-gray-300 text-black font-semibold py-2 px-4 rounded  opacity-70"
                                disabled
                              >
                                Out of Stock!
                              </button>
                            ) : (
                              <Button
                                color="gray_900"
                                size="sm"
                                leftIcon={
                                  user?.isAuthenticated ? (
                                    <Img
                                      src="/images/cart.svg"
                                      alt="svg"
                                      className="h-[16px] w-[16px]"
                                    />
                                  ) : null
                                }
                                onClick={handleAddtoCart}
                                disabled={isSubmitting}
                                className="min-w-[178px] px-4 gap-2 rounded-[26px] font-medium sm:px-5 disabled:opacity-50"
                              >
                                {!user?.isAuthenticated ? (
                                  "Login"
                                ) : (
                                  <>
                                    {hasProductInCart
                                      ? "Go to cart"
                                      : isSubmitting
                                      ? "Adding"
                                      : "Add to cart"}
                                  </>
                                )}
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Ratings & Reviews */}
                  <UserReviews
                    reviews={reviews}
                    rating={product?.rating}
                    className=" mt-5"
                  />
                </div>
              </div>
            )}

            {/* recommended products section */}
            <RecommendedProducts />
          </div>
        </div>
      </div>
    </>
  );
}
