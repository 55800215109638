// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import LineChart from "../charts/LineChart";
import React, { useMemo, useState } from "react";
import { MdBarChart } from "react-icons/md";
// Assets
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { lineChartOptionsTotalSpent } from "../charts/variables/charts";
import Card from "../card/Card";
import { darkCardBg } from "../../../constants";
import {
  calculatePercentageDifference,
  formatAmount,
  getMonthlyData,
  getYearlyData,
  MONTH_NAMES,
} from "../../../utils";

export default function TotalSales({ data }) {
  const [timeFrame, setTimeFrame] = useState("monthly");
  const [lineChart, setLineChart] = useState({
    data: [
      {
        name: "Revenue",
        data: getMonthlyData(data?.revenuePerMonth || [], "totalRevenue"),
      },
      {
        name: "Orders",
        data: getMonthlyData(data?.ordersPerMonth || [], "count"),
      },
    ],
    options: {
      ...lineChartOptionsTotalSpent,
      xaxis: { ...lineChartOptionsTotalSpent.xaxis, categories: MONTH_NAMES },
    },
  });
  const bgCard = useColorModeValue("white", darkCardBg);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const salesGrowth = useMemo(() => {
    let current, previous;

    if (timeFrame === "monthly") {
      const { revenuePerMonth } = data;
      if (revenuePerMonth.length) {
        current = revenuePerMonth.at(-1)?.totalRevenue; // Last month
        const secondLastItem = revenuePerMonth.at(-2); // Month before last
        previous = secondLastItem ? secondLastItem?.totalRevenue : 0;
      }
    } else if (timeFrame === "yearly") {
      const { revenuePerYear } = data;
      if (revenuePerYear.length) {
        current = revenuePerYear.at(-1)?.totalRevenue; // Last year
        const secondLastItem = revenuePerYear.at(-2); // Year before last
        previous = secondLastItem ? secondLastItem?.totalRevenue : 0;
      }
    }
    return calculatePercentageDifference(previous, current);
  }, [data, timeFrame]);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setTimeFrame(value);
    switch (value) {
      case "monthly": {
        setLineChart((prev) => ({
          data: [
            {
              name: "Revenue",
              data: getMonthlyData(data?.revenuePerMonth || [], "totalRevenue"),
            },
            {
              name: "Orders",
              data: getMonthlyData(data?.ordersPerMonth || [], "count"),
            },
          ],
          options: {
            ...prev.options,
            xaxis: { ...prev.options.xaxis, categories: MONTH_NAMES },
          },
        }));
        return;
      }
      case "yearly": {
        const { yearlyData, label } = getYearlyData(
          data?.revenuePerYear,
          "totalRevenue"
        );
        const { yearlyData: OrdersData, label: ordersLabel } = getYearlyData(
          data?.OrdersPerYear,
          "count"
        );
        console.log(ordersLabel, OrdersData);
        setLineChart((prev) => ({
          data: [
            {
              name: "Revenue",
              data: yearlyData,
            },
            {
              name: "Orders",
              data: OrdersData,
            },
          ],
          options: {
            ...prev.options,
            xaxis: { ...prev.options.xaxis, categories: label },
          },
        }));
        return;
      }

      default:
        return null;
    }
  };
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      p="20px"
      bg={bgCard}
      borderRadius="xl"
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px" mb={-5}>
        <Flex align="center" w="100%">
          {/* <Button
            bg={boxBg}
            fontSize="sm"
            fontWeight="500"
            color={textColorSecondary}
            borderRadius="7px"
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
            />
            This month
          </Button> */}
          <Select
            fontSize="sm"
            variant="subtle"
            defaultValue="monthly"
            value={timeFrame}
            width="unset"
            fontWeight="700"
            onChange={handleSelectChange}
            mb={0}
          >
            {/* <option selected value="daily">
              Daily
            </option> */}
            <option selected value="monthly">
              Monthly
            </option>
            <option value="yearly">Yearly</option>
          </Select>
          <Button
            ms="auto"
            align="center"
            justifyContent="center"
            bg={bgButton}
            _hover={bgHover}
            _focus={bgFocus}
            _active={bgFocus}
            w="37px"
            h="37px"
            lineHeight="100%"
            borderRadius="10px"
          >
            <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
          </Button>
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            $
            {formatAmount(
              data?.revenuePerMonth.length
                ? data.revenuePerMonth.at(-1)?.totalRevenue
                : null
            )}
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Total Sales
            </Text>
            {salesGrowth && (
              <Flex align="center">
                {salesGrowth < 0 ? (
                  <Icon
                    as={RiArrowDownSFill}
                    color="red.500"
                    me="2px"
                    mt="2px"
                  />
                ) : (
                  <Icon
                    as={RiArrowUpSFill}
                    color="green.500"
                    me="2px"
                    mt="2px"
                  />
                )}
                <Text
                  color={salesGrowth < 0 ? "red.500" : "green.500"}
                  fontSize="sm"
                  fontWeight="700"
                >
                  {salesGrowth < 0 ? `${salesGrowth}%` : `+${salesGrowth}%`}
                </Text>
              </Flex>
            )}
          </Flex>

          {/* <Flex align="center">
            <Icon as={IoCheckmarkCircle} color="green.500" me="4px" />
            <Text color="green.500" fontSize="md" fontWeight="700">
              On track
            </Text>
          </Flex> */}
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          <LineChart
            chartData={lineChart.data}
            chartOptions={lineChart.options}
          />
        </Box>
      </Flex>
    </Card>
  );
}
