import React, { useEffect } from "react";
import BannerList from "../../../components/AdminComponents/Banners";
import { usePageTitle } from "../../../context/PageTitle";

const Banners = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Banners"), []);
  return (
    <div className="flex h-full ">
      <BannerList />
    </div>
  );
};

export default Banners;
