import React, { useEffect } from "react";
import OrdersList from "../../../components/AdminComponents/Orders";
import { usePageTitle } from "../../../context/PageTitle";

const Orders = () => {
  const { setPageTitle } = usePageTitle();
  useEffect(() => setPageTitle("Orders"), []);
  return (
    <div className="flex h-full">
      <OrdersList />
    </div>
  );
};

export default Orders;
