import React from "react";
import { Trash2 } from "lucide-react";
import { Button, Img } from "../../../components/UserComponents";
import { Link } from "react-router-dom";
import { truncate } from "lodash";
const WishlistItem = ({
  _id,
  thumbnail,
  name,
  stock,
  price,
  actualPrice,
  discount,
  offer,
  handleAddtoCart,
  handleDeleteFromWishlist,
}) => {
  return (
    <div
      key={_id}
      className="flex flex-col max-w-sm bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg hover:-translate-y-1"
    >
      <Link to={`/product/${_id}`}>
        <div className="relative overflow-hidden">
          <img
            src={thumbnail}
            alt={name}
            className="w-full h-full object-contain lg:object-cover"
          />
          <button
            onClick={handleDeleteFromWishlist}
            className="absolute top-2 right-2 bg-white p-1 rounded-full shadow-md hover:bg-red-50"
          >
            <Trash2 className="h-5 w-5 text-red-500" />
          </button>
          {offer?.isActive && (
            <span className="absolute bottom-2 left-2 bg-green-500 text-white px-2 py-1 rounded-md text-sm font-medium">
              {discount || 0} % off
            </span>
          )}
        </div>
      </Link>
      <div className="p-4 flex flex-col">
        <h3 className="font-semibold sm:text-xs text-sm mb-2">
          {truncate(name, { length: 25 })}
        </h3>
        <div className="flex items-center mb-2">
          <span className="sm:text-sm text-base font-bold text-blue-600 mr-2">
            ${price}
          </span>
          {price !== actualPrice && (
            <span className="sm:text-xs text-sm text-gray-500 line-through">
              ${actualPrice}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center mt-auto p-2">
        <Button
          color="gray_900"
          size="sm"
          leftIcon={
            <Img
              src="/images/cart.svg"
              alt="svg"
              className="size-5 sm:hidden block"
            />
          }
          disabled={stock < 1}
          onClick={handleAddtoCart}
          className="!w-full gap-2 sm:text-xs text-sm rounded-[26px] px-5 font-medium sm:px-5 disabled:opacity-50"
        >
          {stock < 1 ? "Out of stock" : "Move to cart"}
        </Button>
      </div>
    </div>
  );
};

export default WishlistItem;
