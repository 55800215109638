import { ADMIN_URL, productHeaders } from "../../../constants";
import AddorEditProduct from "./dialogue/AddorEditProduct";
import useDisableBodyScroll from "../../../Hooks/useDisableBodyScroll";
import useFetchApi from "../../../Hooks/useFetchApi";
import ProductRow from "./ProductRow";
import AvailableOffers from "./dialogue/AvailableOffers";
import Loader from "../../UserComponents/Loader";
import updateArrayObjects from "../../../utils";
import { useEffect, useState } from "react";
import { SearchBar } from "../Header/SearchBar";
import { SlidersHorizontal } from "lucide-react";
import useDebounce from "../../../Hooks/useDebounce";
import useFilter from "../../../Hooks/useFilter";
import FilterDrawer from "./FilterDrawer";
import { useDisclosure } from "@chakra-ui/react";

const ProductList = () => {
  const {
    data: products,
    isLoading,
    setData: setProducts,
    setFilters,
    currentPage,
    pageSize,
    handleOnPageChange,
    itemsPerPage,
  } = useFetchApi({
    url: ADMIN_URL + "/products",
  });
  const [productModal, setProductModal] = useState({
    show: false,
    isEditing: false,
    productData: null,
  });
  const [offerListModal, setOfferListModal] = useState({
    show: false,
    productId: null,
    isOfferActive: null,
    offerId: null,
  });
  useDisableBodyScroll(productModal.show);

  const { filterValues, handleFiltersSelection, resetFilters } = useFilter({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const debouncedSearch = useDebounce(filterValues.search, 500);
  useEffect(() => {
    // handleOnPageChange(1);
    setFilters({ ...filterValues, search: debouncedSearch });
  }, [debouncedSearch]);

  const handleNewProduct = (newProduct) => {
    setProducts((prev) => [...prev, newProduct]);
  };

  const handleEditProduct = (productData) => {
    setProductModal({
      isEditing: true,
      show: true,
      productData,
    });
  };

  const handleVisibility = () => {
    setProductModal({ show: false, isEditing: false, productData: null });
  };
  const handleOfferApply = (product) => {
    const { _id, isOfferActive, offer } = product;
    const offerId = isOfferActive ? offer?.offerId : null;
    setOfferListModal({
      show: true,
      productId: _id,
      hasOffer: isOfferActive,
      offerId,
    });
  };

  const handleOfferModalVisibility = (offerUpdatedData) => {
    if (offerUpdatedData) {
      const updatedProducts = updateArrayObjects(
        products,
        "_id",
        offerListModal.productId,
        offerUpdatedData
      );
      setProducts(updatedProducts);
    }
    setOfferListModal({
      show: false,
      productId: null,
      offerId: null,
      isOfferActive: null,
    });
  };
  const handleFilterApply = (isSubmitting = false) => {
    isSubmitting && setFilters(filterValues);
  };
  return (
    <div className={`p-5 w-full h-full`}>
      <div className="flex sm:flex-col justify-between gap-2 item-center mb-2">
        <div className="flex gap-2 items-center justify-center2">
          <SearchBar
            onChange={(e) => handleFiltersSelection({ search: e.target.value })}
            onClick={() => setFilters(filterValues)}
          />
          <button
            onClick={onOpen}
            className="bg-blue-500 text-white-A700 p-2.5 rounded-full hover:bg-blue-600 focus:outline-none"
          >
            <SlidersHorizontal className="size-4" />
          </button>
          <FilterDrawer
            isOpen={isOpen}
            onClose={onClose}
            filterValues={filterValues}
            handleFilterApply={handleFilterApply}
            handleFiltersSelection={handleFiltersSelection}
            resetFilters={resetFilters}
          />
        </div>
        <button
          type="button"
          onClick={() => setProductModal((prev) => ({ ...prev, show: true }))}
          className="text-white-A700 bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
        >
          Add Product
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto border bg-white-A700 dark:bg-dark rounded-2xl">
          <table className="min-w-full overflow-x-auto">
            <thead>
              <tr>
                {productHeaders.map((header, i) => (
                  <th className="py-2 px-4 border" key={i}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.length ? (
                <>
                  {products.map((product) => (
                    <ProductRow
                      {...product}
                      key={product?._id}
                      handleEditProduct={() => handleEditProduct(product)}
                      handleApplyOfferClick={() => handleOfferApply(product)}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td>
                    <h3>No products</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            totalCount={pageSize}
            itemsPerPage={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        </div>
      )}
      {/* create product modal */}
      {productModal.show && !productModal.isEditing && (
        <AddorEditProduct
          handleNewProduct={handleNewProduct}
          handleVisibility={() =>
            setProductModal((prev) => ({
              ...prev,
              show: false,
            }))
          }
        />
      )}

      {/* Product editing modal */}
      {productModal.show && productModal.isEditing && (
        <AddorEditProduct
          isEditing={true}
          handleVisibility={handleVisibility}
          productData={productModal.productData}
        />
      )}
      {/* Available offers list */}
      {offerListModal.show && (
        <AvailableOffers
          offerData={{ type: "product", objectId: offerListModal.productId }}
          offerStatus={offerListModal}
          handlevisibilty={handleOfferModalVisibility}
        />
      )}
    </div>
  );
};

export default ProductList;
